<template>
    <div class="creator-program-container">
        <h3>Creator Dashboard</h3>
        <div class="commissions">
            <div class="container d-flex justify-content-between align-items-center mb-2">
                <h3 class="mb-1">
                    Earnings
                </h3>
                <div class="d-flex follow-button">
                    <div v-for="({ name, filter }) in filters" :key="`${filter}`">
                        <button :class="currentfilter === filter ? 'btn': 'btn btn-selected'" @click="currentfilter = filter">
                            {{ name }}
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <line-chart
                    :chart-data="datacollection"
                    :options="options"
                    :height:="200"
                    :width="520"
                />
            </div>
            <div class="gained">
                <span>
                    Gained
                </span>
                <h3>
                    ${{ total }}
                </h3>
            </div>
        </div>
        <div class="commissions">
            <h3 class="mb-1">
                Commissions
            </h3>
            <div v-for="(commission, index) in commissions" :key="index">
                <div class="commision-card">
                    <div class="info-container">
                        <img :src="commission.user.photo">
                        <div class="info">
                            <h3>{{ commission.user.displayname }}</h3>
                            <span>{{ `${ commission.commission.user.displayname } commissioned you Jun 2` }}</span>
                        </div>
                    </div>
                    <span class="earn">{{ `$${ commission.commission.amount }` }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LineChart from "./LineChart";
import { months } from "./Utils";
import { mapState } from "vuex";

export default {
    name: "CreatorDashboardSetting",
    components: { LineChart },
    data: () => ({
        datacollection: null,
        commissions: [],
        chartData: [],
        currentfilter: "all",
        options: {
            legend: { display: false }
        },
        filters: [
            { name: "1w", filter: "1week" },
            { name: "1m", filter: "1month" },
            { name: "6m", filter: "6month" }, 
            { name: "1y", filter: "1year" }, 
            { name: "All", filter: "all" }
        ]

    }),
    computed: {
        ...mapState({
            usarData: state => state.User.data
        }),
        total() {
            return this.chartData.reduce((a, c) => a + c, 0);
        }
    },
    watch: {
        currentfilter() {
            this.setPaymentData();
        }
    },
    mounted() {
        this.setPaymentData();
    },
    methods: {
        async setPaymentData() {
            const { data: { commissions, dashboard } } = 
                await axiosV2.get(`/founders-reporting/commission?filter=${ this.currentfilter }`);

            this.commissions = commissions;
            this.chartData = dashboard;
            this.fillData();
        },
        fillData() {
            const DATA_COUNT = 7;
            const labels = months({ count: DATA_COUNT });

            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d")
 
            const gradient = context.createLinearGradient(0, 0, 0, 300);
            gradient.addColorStop(0, "rgb(5, 168, 143)");
            gradient.addColorStop(1, "rgba(44, 44, 46, 0.68)");
            context.fillStyle = gradient;
            context.fillRect(0, 0, 300, 300);

            this.datacollection = {
                labels,
                datasets: [
                    {
                        label: "All",
                        data: this.chartData,
                        fill: true,
                        backgroundColor: gradient,
                        borderColor: "rgb(5, 168, 143)"
                    }
                ]
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .commissions {
        width: 570px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background: #1C1C1E;
        border-radius: 22px;
        margin-top: 40px;
    }

    .gained {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .commision-card {
        display: flex;
        align-items: center;
        background: #2C2C2E;
        border-radius: 4px;
        margin: 10px 0px;
        padding: 12px;

        .info-container {
            display: flex;
            justify-content: space-between;
            margin-right: 200px;
        }

        img {
            width: 40px;
            height: 39.47px;
            border-radius: 30px;
            margin: 0px 12px;
            background: red;
            border: none;
            object-fit: cover;
            // border-radius: 100%;
        }

        .info {
            margin: 0 10px;

            h3 {
                font-size: 16px;
                margin: 0;
            }

            display: flex;
            flex-direction: column;

        }

        .earn {
            color: #05A88F;
        }
    }
    
    .btn {
        width: 44px;
        height: 32px;
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
        background-color: rgba(255, 255, 255, 0.8);
        color: rgb(40,40,41);
        padding: 8px 0px;
        margin-left: 5px;

        &:focus {
            box-shadow: none;
        }
    }

    .btn-selected {
        width: 44px;
        height: 32px;
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
        background-color: rgba(255, 255, 255, 0.10);
        color: white;
        padding: 8px 0px;
    }
</style>
